import React, { useRef, useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import videoSrc from "../images/4Phone_1.mp4"
import useMeasure from "../hooks/useMeasure"
import useWindowWidth from "../hooks/useWindowWidth"
import useWindowHeight from "../hooks/useWindowHeight"
import logoSrc from "../images/kyrah.png"
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa"

const Container = styled(motion.div)`
  height: 100vh;
  background: #8e5543bf;
  background: rgba(112, 139, 151, 0.7);
  display: grid;
  grid-template-columns: auto 1fr;
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 2fr;
    video {
      height: 100vh;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
`
const BirdContainer = styled(motion.div)`
  display: relative;
  height: 100%;
  overflow: hidden;
  display: grid;
  justify-content: center;
  grid-template-rows: 40fr 55fr 10fr;
`

const Bird = styled(motion.svg)`
  background: none;
  color: rgba(0, 0, 0, 0.15);
  width: 40rem;
  height: 40rem;
  position: absolute;
`

const Video = styled(motion.video)`
  height: 100%;
`

const Logo = styled.img`
  display: block;
  max-width: 100%;
`

const VideoLogo = styled(Logo)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
`

const Biography = styled(motion.div)`
  p {
    max-width: 80ch;
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: white;
    border-left: 10px solid #2f2623;
    text-align: justify;
    padding-left: 10px;
    span {
      display: inline;
      background: rgba(47, 38, 35, 0.59);
      color: white;
      padding: 0.005rem;
    }
  }
`

const SvgBird = ({ x, y, scale }) => {
  return (
    <Bird
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 125"
      enable-background="new 0 0 100 100"
      initial={{
        opacity: 0,
        scale: 0,
        y: `${Math.random() * 1000}px`,
        x: `${Math.random() * 1000}px`,
        rotate: Math.random() * 100,
      }}
      animate={{ x: x, y, scale, opacity: 0.3 }}
      transition={{ duration: 1 }}
    >
      <polygon
        fill="currentColor"
        points="65.224,29.075 56.42,52.762 83.042,61.147  "
      />
      <polygon
        fill="currentColor"
        points="55.159,54.907 27.8,95.033 56.172,73.146  "
      />
      <polygon
        fill="currentColor"
        points="57.321,72.564 82.544,62.226 56.287,53.957  "
      />
      <polygon
        fill="currentColor"
        points="75.44,45.122 84.033,60.511 88.43,40.525  "
      />
      <polygon
        fill="currentColor"
        points="55.45,51.987 64.376,27.972 15.921,5.233  "
      />
    </Bird>
  )
}

const IconRow = styled.div`
  display: flex;
  a:not(:first-of-type) {
    margin-left: 3rem;
  }
`

const IconButton = styled.a`
  display: block;
  cursor: pointer;
  svg {
    width: 3.6rem;
    height: 3.6rem;
    fill: #2f2623;
    transition: all 0.3s;
    &:hover {
      fill: black;
      transform: scale(1.1);
    }
  }
`

const MobileContainer = styled.div`
  background: rgba(112, 139, 151, 0.7);
  padding: 0 ${props => (props.padding ? "1rem" : 0)};
  display: grid;
  place-items: center;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .birdContainer {
    overflow-y: auto;
    display: block;
    p {
      font-size: 1.4rem;
      border-left: none;
      padding: 0;
      background: none;
    }
    span {
      color: black;
      background: none;
    }
  }
  .iconRow {
    margin-top: 4rem;
  }
`

const MobileVideoContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  video {
    width: 100vw;
    object-fit: cover;
    display: block;
  }
  button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #bb2f3d;
    border: none;
    padding: 1.5rem 3rem;
    letter-spacing: 0.5rem;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    color: white;
    font-size: 2rem;
    font-family: "Quattrocento Sans", sans-serif;
    border-radius: 5px;
    font-weight: bold;
    display: block;
    width: 100%;
    outline: none;
    &:focus {
      border: 1px solid black;
    }
  }
`

const IndexPage = () => {
  const ref = useRef()
  const { width, height } = useMeasure(ref)
  const windowWidth = useWindowWidth()
  const windowHeight = useWindowHeight()
  const [isVideoActive, setIsVideoActive] = useState(true)
  const [birds, setBirds] = useState([
    {
      x: `${width * 0.2}px`,
      y: `${height * 0.2}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.5}px`,
      y: `${height * 0.2}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.3}px`,
      y: `${height * 0.7}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.5}px`,
      y: `${height * 0.4}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.1}px`,
      y: `${height * 0.1}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.8}px`,
      y: `${height * 0.1}px`,
      scale: Math.max(0.3, Math.random()),
    },
    {
      x: `${width * 0.1}px`,
      y: `${height * 0.5}px`,
      scale: Math.max(0.3, Math.random()),
    },
  ])

  useEffect(() => {
    if (typeof window === "undefined") return
    console.log("asd")
    const container = document.getElementById("birdContainer")
    const containerWidth = container?.clientWidth || windowWidth
    const containerHeight = container?.clientWidth || windowHeight
    console.log({ containerWidth, width, height, windowWidth })
    setBirds([
      {
        x: `${containerWidth * 0.2}px`,
        y: `${containerHeight * 0.2}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.5}px`,
        y: `${containerHeight * 0.2}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.3}px`,
        y: `${containerHeight * 0.7}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.5}px`,
        y: `${containerHeight * 0.4}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.1}px`,
        y: `${containerHeight * 0.1}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.8}px`,
        y: `${containerHeight * 0.1}px`,
        scale: Math.max(0.3, Math.random()),
      },
      {
        x: `${containerWidth * 0.1}px`,
        y: `${containerHeight * 0.5}px`,
        scale: Math.max(0.3, Math.random()),
      },
    ])
  }, [windowWidth, width, height])

  const mobileBirds = [
    {
      x: `${width * 0.7}px`,
      y: `${height * -0.3}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
    {
      x: `${width * 0.4}px`,
      y: `${height * -0.2}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
    {
      x: `${width * 0.2}px`,
      y: `${height * -0.1}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
    {
      x: `${width * 0.5}px`,
      y: `${height * 0.1}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
    {
      x: `${width * 0.3}px`,
      y: `${height * 0.2}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
    {
      x: `${width * 0.6}px`,
      y: `${height * 0.3}px`,
      scale: Math.max(0.2, Math.random()) * 0.5,
    },
  ]

  const Desktop = (
    <Container>
      <Video
        src={videoSrc}
        muted
        autoPlay
        playsInline
        loop
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      />
      <BirdContainer id="birdContainer" ref={ref}>
        <Logo src={logoSrc} alt="kyrah" />
        {birds.map((b, i) => (
          <SvgBird key={i} x={b.x} y={b.y} scale={b.scale} />
        ))}
        <Biography
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, rotate: -2 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <p>
            <span>
              Fliegen sollte der Mensch können. Dem Boden entfliehen, abheben
              und durch die Luft gleiten. Dabei die Leichtigkeit des Lebens
              spüren, Risiken wagen und nicht aufgeben. Dieses Gefühl schafft
              Kyrah durch ihre eingängigen Melodien. In ihrer Musik werden die
              Grenzen des Soul und Pop aufgelöst, was bleibt sind Songs, die
              ehrlich, direkt und eigenständig sind. Jeder Song hat seine
              eigenen Facetten, die Konstante ist Kyrahs Botschaft: Das Handeln
              jedes Einzelnen hinterlässt seine Spuren auf der Welt und löst
              Bewegung aus. Diese Botschaft bringt sie nicht nur durch ihre
              wiedererkennbare Stimme sondern auch durch ihre tänzerischen
              Fähigkeiten zum Ausdruck. Zusammen mit zwei Tänzerinnen und einem
              Schlagzeuger steht sie auf der Bühne und nimmt das Publikum sanft
              auf eine Reise in andere Sphären mit. Genre: Pop
            </span>
          </p>
        </Biography>
        <IconRow>
          <IconButton
            href="https://www.facebook.com/kyrahmusicofficial"
            alt="Kyrah on Facebook"
          >
            <FaFacebook />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/kyrah_music/"
            alt="Kyrah on Instagram"
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            href="mailto:kira.stahel@gmail.com"
            alt="Email Adresse von Kyrah"
          >
            <FaEnvelope />
          </IconButton>
        </IconRow>
      </BirdContainer>
    </Container>
  )
  const Mobile = (
    <MobileContainer
      ref={ref}
      padding={!isVideoActive}
      style={{
        height: `${windowHeight}px` || "100vh",
        maxHeight: `${windowHeight}px`,
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {isVideoActive && (
          <MobileVideoContainer
            style={{ perspective: "2000px", transformStyle: "preserve-3d" }}
            initial={{ opacity: 0, rotateY: 180 }}
            animate={{ opacity: 1, rotateY: 0 }}
            exit={{ opacity: 0, rotateY: -180 }}
          >
            <VideoLogo
              src={logoSrc}
              alt="kyrah"
              onClick={() => setIsVideoActive(false)}
            />
            <Video
              src={videoSrc}
              muted
              autoPlay
              playsInline
              loop
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
            <button onClick={() => setIsVideoActive(false)}>Info</button>
          </MobileVideoContainer>
        )}
        {!isVideoActive && (
          <BirdContainer
            style={{ perspective: "2000px", transformStyle: "preserve-3d" }}
            initial={{ opacity: 0, rotateY: 180 }}
            animate={{ opacity: 1, rotateY: 0 }}
            exit={{ opacity: 0, rotateY: -180 }}
            className="birdContainer"
          >
            <Logo
              src={logoSrc}
              alt="kyrah"
              onClick={() => setIsVideoActive(true)}
            />
            {mobileBirds.map((b, i) => (
              <SvgBird key={i} x={b.x} y={b.y} scale={b.scale} />
            ))}
            <Biography className="bio">
              <p>
                <span>
                  Fliegen sollte der Mensch können. Dem Boden entfliehen,
                  abheben und durch die Luft gleiten. Dabei die Leichtigkeit des
                  Lebens spüren, Risiken wagen und nicht aufgeben. Diese
                  Botschaft bringt Kyrah nicht nur durch ihre wiedererkennbare
                  Stimme sondern auch durch ihre tänzerischen Fähigkeiten zum
                  Ausdruck. Zusammen mit zwei Tänzerinnen und einem Schlagzeuger
                  steht sie auf der Bühne und nimmt das Publikum sanft auf eine
                  Reise in andere Sphären mit. <br /> <br />
                  Genre: Pop
                </span>
              </p>
            </Biography>
            <IconRow className="iconRow">
              <IconButton
                href="https://www.facebook.com/kyrahmusicofficial"
                alt="Kyrah on Facebook"
              >
                <FaFacebook />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/kyrah_music/"
                alt="Kyrah on Instagram"
              >
                <FaInstagram />
              </IconButton>
              <IconButton
                href="mailto:kira.stahel@gmail.com"
                alt="Email Adresse von Kyrah"
              >
                <FaEnvelope />
              </IconButton>
            </IconRow>
          </BirdContainer>
        )}
      </AnimatePresence>
    </MobileContainer>
  )
  if (typeof window === `undefined`) {
    return <></>
  }
  return (
    <Layout>
      <SEO />
      {windowWidth > 1280 ? Desktop : Mobile}
    </Layout>
  )
}

export default IndexPage
